import React from "react"
import Seo from "../components/utilities/Seo"
import { genericPageHolder } from './pages.module.scss'

export default function PrivacyPage() {
  return (
    <article className={genericPageHolder}>
      <Seo
        title="Privacy Policy"
        description="Privacy Policy for Carbon Climate Solutions"
      />
      <h2>Privacy Policy</h2>
      <p>Carbon Climate Solutions is committed to protecting and respecting your privacy. This privacy policy tells you what to expect when we collect personal information.</p>
      <p>By visiting our websites or using our services, you agree that we can use your personal information for the purposes described in this privacy policy.</p>
      <p>This privacy policy does not cover the links within our websites linking to other websites. We encourage you to read the privacy statements on the other websites you visit.</p>
      <h3>Definitions</h3>
      <p>When we refer to ‘we’, ‘us’, and ‘our’, we mean Carbon Climate Solutions.</p>
      <p>We also refer to ‘personal information’ and by this we mean information which relates to or is obviously about you, or from which you can be identified.</p>

      <h3>Processing your personal information</h3>

      <p>We may collect personal information about:</p>
      <ul>
        <li>visits to our websites</li>
        <li>enquiries about our products or services via the contact form</li>
        <li>your email details via our mailing list form</li>
      </ul>

      <h3>Using your personal information</h3>
        <p>We use personal information to:</p>
      <ul>
        <li>ensure that content from our websites is presented in the most effective manner for you and for your device</li>
        <li>provide you with information, products or services that you request from us or which we feel may interest you, where you have consented to be contacted for such purposes</li>
        <li>carry out our obligations arising from any contracts entered into between you and us including accounting, billing and audit, credit or other payment card verification and anti-fraud screening</li>
        <li>allow you to participate in interactive features of our service, when you choose to do so, and notify you about changes to our products and services.</li>
      </ul>

      <h3>Security</h3>
      <p>We take appropriate organisational and technical security measures to protect personal information that we hold against unauthorised disclosure or unlawful processing. Personal information transmitted to Carbon Climate Solutions websites are held on our secure servers and encrypted. However, the transmission of information via the internet is not completely secure and we cannot guarantee the security of your information transmitted to our websites; any transmission is at your own risk.</p>
      <p>Where we have given you (or where you have chosen) a password which enables you to access certain parts of our websites, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>

      <h3>Use of Cookies</h3>
      <p>What are cookies? Cookies are small data files containing unique identifiers, which are sent to your device when you visit a website. Cookies are used to improve your online experience to:</p>
  <ul>
    <li>Make our website operate as you'd expect</li>
    <li>Remember your settings during and between visits</li>
    <li>Improve the speed/security of the site</li>
    <li>Allow you to share pages with social networks like Facebook</li>
    <li>Continuously improve our website for you</li>
    <li>Make our marketing more efficient</li>
  </ul>

  <h3>Types of Cookies</h3>
  <h5>Category 1 Cookies</h5>
<p>These cookies are essential in order to enable you to move around the website and use its features, such as accessing secure areas of the website. Without these cookies services you have asked for, like shopping baskets or e-billing, cannot be provided.</p>

<p>User consent is not required for the delivery of those cookies which are strictly necessary to provide services requested by the user.</p>

<h5>Category 2 Cookies</h5>
<p>These cookies collect information about how visitors use a website, for e.g. which pages visitors go to most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous. It is only used to improve how a website works.</p>

<p>Web analytics that use cookies to gather data to enhance the performance of a website fall into this category. For example, they may be used for testing designs and ensuring a consistent look and feel is maintained for the user. This category does not include cookies used for behavioural/ targeted advertising networks.</p>

<h5>Category 3 Cookies</h5>
<p>These cookies allow the website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features. For instance, a website may be able to provide you with local weather reports or traffic news by storing in a cookie the region in which you are currently located. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymized and they cannot track your browsing activity on other websites.</p>

<p>These cookies are used to remember customer selections that change the way the site behaves or looks. It might also include cookies that are used to deliver a specific function, but where that function includes cookies used for behavioural/targeted advertising networks they must be included in category 4 as well as this category.</p>

<p>By using secure Dr Tom Bereznicki websites you agree that we can place these types of cookies on your device</p>

<h5>Category 4 Cookies</h5>
<p>These cookies are used to deliver adverts more relevant to you and your interests They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaign. They are usually placed by advertising networks with the website operator’s permission. They remember that you have visited a website and this information is shared with other organisations such as advertisers. Quite often targeting or advertising cookies will be linked to site functionality provided by the other organization.</p>

<p>Definitions supplied by the International Chamber of Commerce ‘ICC UK Cookie Guide’ April 2012.</p>

<h5>Social Website Cookies</h5>
<p>So you can easily “Like” or share our content on the likes of Facebook and Twitter we have included sharing buttons on our sites.</p>

<p>The privacy implications on this will vary from social network to social network and will be dependent on the privacy settings you have chosen on these networks.</p>

<p>For information on how to delete cookies, please refer to:https://us.norton.com/internetsecurity-privacy-how-to-clear-cookies.html</p>

<p>We do not use cookies to:</p>
<ul>
  <li>Collect any personally identifiable information without your express permission</li>
  <li>Collect any sensitive information without your express permission</li>
  <li>Pass personally identifiable data to third parties</li>
  <li>Pay sales commissions</li>
</ul>

      <h3>Disclosure of personal information</h3>
      <p>We may disclose your personal information (to the extent necessary) to any member of our group, which means our subsidiaries.We may use third parties to process your personal information on our behalf.</p>

      <p>Where we do so, we ensure that they process information in accordance with our confidentiality and security requirements as well as in accordance with data protection requirements.</p>

      <p>We may also disclose your personal information where we are required by law.</p>


      <h3>Contact</h3>
      <p>If you would like to know more about anything in this privacy statement, please email the webmaster at ben@mrbjjackson.com</p>

      <p>or write to the Data Protection Officer,</p>

      <p>Ben Jackson,</p>
      <p>39 Rosemundy,</p>
      <p>TR5 0UE</p>


    </article>
  )
}
