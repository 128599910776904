// extracted by mini-css-extract-plugin
export var indexHolder = "pages-module--indexHolder--1DmVP";
export var benefitsHolder = "pages-module--benefitsHolder--1NaAc";
export var expertiseHolder = "pages-module--expertiseHolder--wXPx1";
export var achievementsHolder = "pages-module--achievementsHolder--3TXsw";
export var servicesHolder = "pages-module--servicesHolder--3Wlfn";
export var videoHolder = "pages-module--videoHolder--37hHZ";
export var ourProjectsHolder = "pages-module--ourProjectsHolder--2GzbC";
export var ourPartnersAndClientsHolder = "pages-module--ourPartnersAndClientsHolder--2Jv10";
export var contactHolder = "pages-module--contactHolder--3iUOO";
export var genericPageHolder = "pages-module--genericPageHolder--1DrJ7";